import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface DataAsListProps {
    data: string[]
    fieldId: string
}

const DataAsList: React.FC<DataAsListProps> = (props) => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 2
    }}>
        {props.data.map(
            (entry: string, index: number) => {
                return <Typography
                    key={`${props.fieldId}_${index}`}
                    variant='bodyLarge'
                    style={{whiteSpace:'break-spaces'}}>
                    {entry}
                </Typography>
            })}
    </Box>
}

export default DataAsList