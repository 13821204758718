import moment, { Moment } from "moment/moment";
import 'moment/locale/es';
import { PaidService } from "interfaces/paidService";
import { IAdvertisement, IPackage, IPayment } from "interfaces";
import { CatalogEntry } from "interfaces/catalogEntry";
import { GeocodeResult } from "use-places-autocomplete";
import paths from "constants/paths";
import { Group, GroupPackage, GroupRange, Occupancy, PriceTypes, SpaceDetail, WorkingTime } from "interfaces/space";
moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
});

export interface AddressComponents {
    city?: string
    state?: string
    postal?: string
}

export const formatNumberPrice = (price: number, withCurrency: boolean = false, currency: string = 'MXN', hasDecimals: boolean = true): string => {
    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency,
        minimumFractionDigits: hasDecimals ? 2 : 0,
    });

    let formattedPrice = formatter.format(price);
    formattedPrice = withCurrency ? formattedPrice + ' ' + currency : formattedPrice;
    return formattedPrice;
}

export const formatTimeString = (date: string): string => {
    const baseMoment = moment(date)
    const dateMoment = moment.parseZone(date).utcOffset(baseMoment.utcOffset() / 60, true)
    return dateMoment.format("hh:mm");
}

export const formatDateString = (date: string): string => {
    const baseMoment = moment(date)
    const dateMoment = moment.parseZone(date).utcOffset(baseMoment.utcOffset() / 60, true)
    return dateMoment.format("DD/MM/YYYY");
}

export const formatFullDateString = (date: string): string => {
    const baseMoment = moment(date)
    const dateMoment = moment.parseZone(date).utcOffset(baseMoment.utcOffset() / 60, true)
    return dateMoment.format("DD [de] MMMM [de] YYYY");
}

export const formatTimeRangeString = (timeRange: string): string => {
    const times: string[] = timeRange.split('-');
    return moment(times[0], 'HH:mm').format("hh:mm A") + ' a ' + moment(times[1], 'HH:mm').format("hh:mm A");
}

export const formatTimeRangeString24 = (timeRange: string): string => {
    const times: string[] = timeRange.split('-');
    return times[0] + '-' + times[1];
}

export const findPackageById = (packages: IPackage[], id?: string): IPackage | undefined => {
    return id !== undefined ? packages.find((currentPackage) => currentPackage._id === id) : undefined
}

export const calculatePriceByPerson = (
    space: SpaceDetail
): number => {
    const maxPeople = space?.occupancy?.maximum !== undefined ? space.occupancy.maximum : 1;
    return space.price ? space.price / maxPeople : 0;
}

export const getCurrentDate = (): string => {
    return moment().format('YYYY-MM-DD');
}

export const formatFullShortDateString = (date: string): string => {
    const baseMoment = moment(date)
    const dateMoment = moment.parseZone(date).utcOffset(baseMoment.utcOffset() / 60, true)
    const day = dateMoment.format("DD");
    let month = dateMoment.format("MMM").replaceAll('.', '');
    month = month.charAt(0).toUpperCase() + month.substring(1).toLowerCase();
    const year = dateMoment.format("YYYY");
    return day + ' de ' + month + ' ' + year;
}

export const formatReviewDateString = (date: Date): string => {
    const baseMoment = moment(date)
    return baseMoment.format('DD MMM yyyy');
}

export const uniqueArray = <T>(objectArray: Array<T>): T[] => {
    return objectArray.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === objectArray.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });
}

export const comparePaidServices = (first: PaidService, second: PaidService): number => {
    if (first.mandatory && !second.mandatory) return -1
    if (!first.mandatory && second.mandatory) return 1
    return 0
}

export const sortPaidServices = (paidServices: PaidService[]): PaidService[] => {
    const sortedPaidServicesByMandatory = paidServices.sort(comparePaidServicesByMandatory)
    const paidServicesMandatories = sortedPaidServicesByMandatory.filter(paidService => paidService.mandatory)
    const sortedPaidServicesMandatoriesByEvent = paidServicesMandatories.sort(comparePaidServicesByEvent)
    const paidServicesGeneral = sortedPaidServicesByMandatory.filter(paidService => !paidService.mandatory)
    const sortedPaidServicesByEvent = paidServicesGeneral.sort(comparePaidServicesByEvent)
    return sortedPaidServicesMandatoriesByEvent.concat(sortedPaidServicesByEvent)
}

const comparePaidServicesByMandatory = (first: PaidService, second: PaidService): number => {
    if (first.mandatory && !second.mandatory) return -1
    if (!first.mandatory && second.mandatory) return 1
    return 0
}

const comparePaidServicesByEvent = (first: PaidService, second: PaidService): number => {
    if (!first.isByPerson && second.isByPerson) return -1
    if (first.isByPerson && !second.isByPerson) return 1
    return 0
}

export const isPaidServiceTheSame = (first: PaidService, second: PaidService) => {
    return first.name === second.name && first.isByPerson === second.isByPerson
}

export const filterPaidServicesById = (paidServices: PaidService[], ids: string[]): PaidService[] => {
    return paidServices.filter(entry => ids.includes(entry.name))
}

export const sumServices = (paidServices: PaidService[], people: number): number => {
    return paidServices.reduce(
        (previousValue, currentValue, currentIndex, array) => {
            return previousValue + (currentValue.isByPerson ? currentValue.price * people : currentValue.price)
        }, 0)
}

export const findValueById = (catalog: CatalogEntry[], id?: string) => {
    if (id === undefined) return undefined
    return catalog.find((entry) => {
        return entry.id === id
    })
}

export const getAddressComponents = (geocodeResult: GeocodeResult): AddressComponents => {
    const city = geocodeResult.address_components.find((component) => {
        return component.types.includes('locality')
    })?.long_name
    const state = geocodeResult.address_components.find((component) => {
        return component.types.includes('administrative_area_level_1')
    })?.long_name
    const postal = geocodeResult.address_components.find((component) => {
        return component.types.includes('postal_code')
    })?.long_name

    return {city, state, postal}
}

export function getValueOrEmptyString(value?: string): string | null {
    if (value === undefined) return ''
    if (value === null) return ''
    return value
}

export const sortDateArray = (dates: string[]): string[] => {
    try {
        return dates.sort((first, second) => {
            const startMoment = moment(first)
            const endMoment = moment(second)
            if (startMoment.isBefore(endMoment)) {
                return -1
            }
            if (startMoment.isAfter(endMoment)) {
                return 1
            }
            return 0
        })
    } catch (e) {
        return dates
    }
}

export const getSelectionsById = (catalog: CatalogEntry[], ids: string[]): CatalogEntry[] => {
    return catalog.filter((entry: CatalogEntry) => ids.includes(entry.id))
}

export const parseCommaPrice = (price: string): number => {
    return parseFloat(price.replaceAll(',', ''))
}

export const isPackageByPerson = (paidServices: PaidService[]) => {
    const initialValue = false
    return paidServices.reduce(
        (previousValue, currentValue) => previousValue || currentValue.isByPerson,
        initialValue
    )
}

export const intersection = (target: CatalogEntry[], other: CatalogEntry[]): CatalogEntry[] => {
    const newArray = [...target]
    return newArray.filter((entry) => {
        const index = other.findIndex((findEntry) => {
            return entry.id === findEntry.id
        })
        return index > -1
    })
}

export const removeAllFrom = (target: CatalogEntry[], from: CatalogEntry[]): CatalogEntry[] => {
    const newArray = [...target]
    from.forEach((entry) => {
        const index = newArray.findIndex(target => target.id === (entry as CatalogEntry).id)
        if (index > -1) {
            newArray.splice(index, 1)
        }
    })
    return newArray
}

export const psRemoveAllFrom = (target: PaidService[], from: PaidService[]): PaidService[] => {
    const newArray = [...target]
    from.forEach((entry) => {
        const index = newArray.findIndex(target => isPaidServiceTheSame(target, entry))
        if (index > -1) {
            newArray.splice(index, 1)
        }
    })
    return newArray
}

export const psIntersection = (target: PaidService[], other: PaidService[]): PaidService[] => {
    const newArray = [...target]
    return newArray.filter((entry, indexNew) => {
        const index = other.findIndex((findEntry) => {
            return isPaidServiceTheSame(entry, findEntry)
        })
        return index > -1 && index === indexNew
    })
}

export const calculatePackagePrice = (
    maxPeople: number,
    paidServices: PaidService[],
    basePrice: number,
    packagePrice: number
): number => {
    const fromServices = paidServices.reduce(
        (previousValue, currentValue, currentIndex, array) => {
            if (currentValue.isByPerson) {
                return previousValue + (currentValue.price * maxPeople)
            } else {
                return previousValue + currentValue.price
            }
        }, 0)
    return basePrice + packagePrice + fromServices
}

export const calculatePricePerPerson = (
    maxPeople: number,
    paidServices: PaidService[],
    basePrice: number,
    packagePrice: number
): number => {
    const maxPrice = calculatePackagePrice(maxPeople, paidServices, basePrice, packagePrice)
    return maxPrice / maxPeople
}

export const dateToUTCMoment = (date: Date): Moment => {
    return moment(date).utc(true).startOf("day")
}

export const dateToUTCString = (date: Date): string => {
    return moment(date).utc(true).startOf("day").toISOString()
}

export const formatTimeRange = (timeRange: string): string => {
    const now = moment()
    const split = timeRange.split('-')

    const startTime = split[0]
    const start = startTime.split(":")
    now.hours(parseInt(start[0]))
    now.minutes(parseInt(start[1]))
    const startText = now.format("HH:mm a")

    const endTime = split[1]
    const end = endTime.split(':')
    now.hours(parseInt(end[0]))
    now.minutes(parseInt(end[1]))
    const endText = now.format("HH:mm a")

    return `${startText} - ${endText}`
}

export const getReservationUrl = (adDetailId: string, checkout: boolean, date?: string, time?: string, packageId?: string, people?: string, paidServices?: string[]): string => {
    let url = `${paths.adAvailability}/${adDetailId}?`
    if (packageId !== undefined && packageId !== null) {
        url += `packageId=${packageId}`
    }
    if (date !== undefined && date !== null) {
        url += `&date=${date}`
    }
    if (time !== undefined && time !== null) {
        url += `&time=${time}`
    }
    if (checkout) {
        url += `&checkout=true`
    }
    if (people !== undefined && people !== null) {
        url += `&people=${people}`
    }
    url += generatePaidServicesParams(paidServices);
    return url
}

const generatePaidServicesParams = (paidServices?: string[]): string => {
    let params = '';
    if (paidServices !== undefined && paidServices !== null && paidServices.length > 0) {
        paidServices.forEach((paidService) => {
            params += `&paidService=${encodeURI(paidService)}`
        })
    }
    return params;
}

export const dateStringToDate = (date: string): Date => {
    const baseMoment = moment(date)
    const dateMoment = moment.parseZone(date).utcOffset(baseMoment.utcOffset() / 60, true)
    return dateMoment.toDate()
}

export const containsPaidService = (paidService: PaidService, paidServices: PaidService[]): boolean => {
    return paidServices.some(elem => {
      return elem.name === paidService.name && elem.price === paidService.price
    })
}

export const roundPricePerPerson = (price: number): number => {
    const multiple = 5
    return multiple * (Math.ceil(Math.abs(price / multiple)))
}

export const getOccupancyString = (fullTemplate: string, halfTemplate: string, occupancy?: Occupancy): string => {
    if (occupancy?.minimum && occupancy?.maximum) {
        return fullTemplate.replace('${min}', occupancy.minimum.toString())
            .replace('${max}', occupancy.maximum.toString())
    }
    if (occupancy?.maximum) {
        return halfTemplate.replace('${max}', occupancy.maximum.toString())
    }
    return ''
}

export const calculateSearchResultMaxPrice = (
    searchResult: SpaceDetail
): number => {
    const maxPeople = searchResult?.occupancy?.maximum !== undefined ? searchResult.occupancy.maximum : 1;
    return searchResult.price ? searchResult.price / maxPeople : 0;
}

export const generateSpaceEventTypesEnable = (spaceDetail: SpaceDetail) => {
    if(!spaceDetail.groups) {
        return spaceDetail.eventTypes
    }
    const spaceEventTypes = [...spaceDetail.eventTypes] as CatalogEntry[]
    spaceDetail.groups.forEach((group: Group) => {
        group.eventTypes.forEach((eventType) => {
            const index = spaceEventTypes.findIndex(value => value.id === (eventType as CatalogEntry).id)
            if(index > -1) {
                spaceEventTypes.splice(index, 1)
            }
        })
    })
    return spaceEventTypes
}

export const checkHasMissingMaxHour = (workingTimes: WorkingTime[]): boolean => {
    for(let workingTime of workingTimes) {
        if(!workingTime.maxHours) {
            return true
        }
    }
    return false
}

export const checkTimesHasMissingHours = (times: string[], maxHours: number): boolean => {
    for(let time of times) {
        if(time.split('-').length === 1) {
            return false
        }
        let totalHours = calculateRangeHours(time)
        if(totalHours < maxHours) {
            return true
        }
    }
    return false
}

export const checkHasOverlapTimes = (input: string[]) => {
    const start = moment()
    const end = moment()
    let result = false
    const times = [...input]

    if (times.length === 1) return false;
    times.sort((timeSegment1, timeSegment2) => {
        const timeSegment1Start = timeSegment1.split('-')[0]
        const timeSegment2Start = timeSegment2.split('-')[0]
        return timeSegment1Start.localeCompare(timeSegment2Start)
    });
    
    for (let i = 0; i < times.length - 1; i++) {
        const currentEndTime = times[i].split('-')[1]
        const nextStartTime = times[i + 1].split('-')[0]

        if (isEndAfterNextStart(end, start, currentEndTime, nextStartTime)) {
            result = true;
        }
    }
    
    return result;
}

export const checkRangesHasMissingData = (ranges: GroupRange[]): boolean => {
    for(let range of ranges) {
        if(
            !range.occupancy.minimum ||
            !range.occupancy.maximum ||
            !range.prices.byEvent ||
            !range.prices.byPerson ||
            !range.prices.byHour
        ) {
            return true
        }
    }
    return false
}

export const checkRangesHasWrong = (ranges: GroupRange[]) => {
    const rangesAux = [...ranges]
    for (let range of rangesAux) {
        if (range.occupancy.maximum! <= range.occupancy.minimum! && range.occupancy.maximum! > 0) {
            return true;
        }
    }

    return false;
}

export const checkRangesHasOccupancyExceeded = (ranges: GroupRange[], spaceOccupancy: Occupancy | undefined): boolean => {
    if(!spaceOccupancy) {
        return true
    }
    for(let range of ranges) {
        if(
            (range.occupancy.minimum! < spaceOccupancy.minimum!) ||
            (range.occupancy.maximum! > spaceOccupancy.maximum!)
        ) {
            return true
        }
    }
    return false
}

const isEndAfterNextStart = (currentEndMoment: moment.Moment, nextStartMoment: moment.Moment, currentEnd: string, nextStart: string): boolean => {
    const endSplit = currentEnd.split(":")
    currentEndMoment.set({hour: parseInt(endSplit[0]), minute: parseInt(endSplit[1])})

    const startSplit = nextStart.split(":")
    nextStartMoment.set({hour: parseInt(startSplit[0]), minute: parseInt(startSplit[1])})
    return currentEndMoment.isAfter(nextStartMoment)
}

export const checkHasWrongTime = (times: string[]): boolean => {
    for(let time of times) {
        if(checkIsEndBeforeStart(time)) {
            return true
        }
    }
    return false
}

const checkIsEndBeforeStart = (time: string): boolean => {
    const startMoment = moment()
    const endMoment = moment()
    const hours = time.split('-')
    const startHour = hours[0].split(":")
    const endHour = hours[1].split(":")
    startMoment.set({hour: parseInt(startHour[0]), minute: parseInt(startHour[1])})
    endMoment.set({hour: parseInt(endHour[0]), minute: parseInt(endHour[1])})
    return endMoment.isBefore(startMoment)
}

export const calculateRangeHours = (timeRange: string): number => {
    const times: string[] = timeRange.split('-')
    const arrStartTime = times[0].split(':')
    const arrEndTime = times[1].split(':')
    const startDate = moment().set('hours', parseInt(arrStartTime[0])).set('minutes', parseInt(arrStartTime[1]))
    const endDate = moment().set('hours', parseInt(arrEndTime[0])).set('minutes', parseInt(arrEndTime[1]))
    if(endDate.isSameOrBefore(startDate)) {
        endDate.add(1, 'day')
    }
    return Math.round(moment.duration(endDate.diff(startDate)).asHours())
}

export const generateSpaceEventTypesDisabled = (spaceDetail: SpaceDetail, groupEventTypes: CatalogEntry[]) => {
    if(!spaceDetail.groups) {
        return []
    }
    const eventTypesDisabled: CatalogEntry[] = []
    spaceDetail.groups.forEach((group: Group) => {
        group.eventTypes.forEach((eventType) => {
            if(!groupEventTypes.includes(eventType as CatalogEntry)) {
                eventTypesDisabled.push(eventType as CatalogEntry)
            }
        })
    })
    return eventTypesDisabled
}

export const checkTimeHasMissingHours = (time: string, maxHours: number): boolean => {
    if(time.split('-').length === 1) {
        return false
    }
    let totalHours = calculateRangeHours(time)
    if(totalHours < maxHours) {
        return true
    }
    return false
}

export const roundAmount = (price: number, multiple: number = 1): number => {
    return multiple * (Math.ceil(Math.abs(price / multiple)))
}

export const checkRangeHasMissingData = (range: GroupRange): boolean => {
    if(
        !range.occupancy.minimum ||
        !range.occupancy.maximum ||
        !range.prices.byEvent ||
        !range.prices.byPerson ||
        !range.prices.byHour
    ) {
        return true
    }
    return false
}

export const checkRangeHasWrong = (range: GroupRange) => {
    if (range.occupancy.maximum! <= range.occupancy.minimum! && range.occupancy.maximum! > 0) {
        return true;
    }
    return false;
}

export const checkRangeHasOccupancyExceeded = (range: GroupRange, spaceOccupancy: Occupancy): boolean => {
    if(
        (range.occupancy.minimum! < spaceOccupancy.minimum!) ||
        (range.occupancy.maximum! > spaceOccupancy.maximum!)
    ) {
        return true
    }
    return false
}

export const getMinOccupancyRange = (workingTimes: WorkingTime[]): GroupRange => {
    let minRange: GroupRange | null = null
    workingTimes.forEach(workingTime => {
        workingTime.ranges.forEach(range => {
            if(minRange === null) {
                minRange = range

            } else if(range.occupancy.minimum! < minRange.occupancy.minimum!) {
                minRange = range
            }
        })
    })
    return minRange!
}

export const getMaxOccupancyRange = (workingTimes: WorkingTime[]): GroupRange => {
    let maxRange: GroupRange | null = null
    workingTimes.forEach(workingTime => {
        workingTime.ranges.forEach(range => {
            if(maxRange === null) {
                maxRange = range
            } else if(range.occupancy.maximum! > maxRange.occupancy.maximum!) {
                maxRange = range
            }
        })
    })
    return maxRange!
}

export const getRangePrice = (range: GroupRange, priceType: PriceTypes): number => {
    switch (priceType) {
        case PriceTypes.ByEvent:
            return range.prices.byEvent

        case PriceTypes.ByPerson:
            return range.prices.byPerson

        case PriceTypes.ByHour:
            return range.prices.byHour
    }
}

export const calculatePackagePricePerPerson = (
    packagePrice: number,
    people: number
): number => {
    return packagePrice / people
}

export const generateCatalogValues = (values: CatalogEntry[]): string => {
    let formattedValue = ''
    values.forEach(value => formattedValue += value.value + ', ' )
    if(formattedValue) {
        formattedValue = formattedValue.substring(0, formattedValue.length - 2)
    }
    return formattedValue
}

export const getSelectedWorkingTime = (group: Group, date: Date | null): WorkingTime | null => {
    if(date === null) {
        return null
    }
    const dateMoment = dateToUTCMoment(date)
    const dayNumber = dateMoment.day()
    const workingTime = group.workingTimes.find(workingTime => workingTime.day === dayNumber)
    return workingTime ? workingTime : null
}

export const getSelectedGroupRangeIndex = (ranges: GroupRange[], people: number): number | null => {
    for(let i = 0;  i<ranges.length; i++) {
        const range = ranges[i]
        if(people >= range.occupancy.minimum! && people <= range.occupancy.maximum!) {
            return i
        }
    }
    return null
}

export const removeHoursToTime = (time: string, hours: number): string => {
    return moment(time, 'HH:mm').subtract(hours, 'hours').format('HH:mm')
}

export const addHoursToTime = (time: string, hours: number): string => {
    return moment(time, 'HH:mm').add(hours, 'hours').format('HH:mm')
}

export const formatTime = (time: string): string => {
    return moment(time, 'HH:mm').format("hh:mm a")
}

export const getSelectedGroupRange = (workingTimes: WorkingTime[], date: Date | null, people: number): GroupRange | null => {
    if(date === null) {
        return null
    }
    const dayNumber = dateToUTCMoment(date).day()
    let selectedWorkingTime: WorkingTime | null = null
    for(let workingTime of workingTimes) {
        if(workingTime.day === dayNumber) {
            selectedWorkingTime = workingTime
            break
        }
    }
    if(selectedWorkingTime !== null) {
        const peopleAux = people
        for(let range of selectedWorkingTime.ranges) {
            if(peopleAux >= range.occupancy.minimum! && peopleAux <= range.occupancy.maximum!) {
                return range
            }
        }
    }
    return null
}

export const calculateGroupBasePrice = (group: Group | null, range: GroupRange | null, people: number, eventHours: number | null): number => {
    let basePrice: number = 0
    if(group === null || range === null) {
        return basePrice
    }
    switch (group.priceType) {
        case PriceTypes.ByEvent:
            basePrice = range.prices.byEvent
            break

        case PriceTypes.ByPerson:
            basePrice = range.prices.byPerson * people
            break

        case PriceTypes.ByHour:
            basePrice = eventHours !== null ? range.prices.byHour * eventHours : 0
            break
    }
    
    return basePrice
}

export const getSelectedGroup = (groups: Group[], eventTypeId: string): Group | null => {
    for(let group of groups) {
        for(let eventType of group.eventTypes) {
            if(typeof eventType === 'string' && eventType === eventTypeId) {
                return group
            } else {
                eventType = eventType as CatalogEntry
                if(eventTypeId === eventType.id) {
                    return group
                }
            }
        }
    }
    return null
}

export const getEvetType = (groups: Group[], eventTypeId: string): CatalogEntry | null => {
    for(let group of groups) {
        for(let eventType of group.eventTypes as CatalogEntry[]) {
            if(eventTypeId === eventType.id) {
                return eventType
            }
        }
    }
    return null
}

export const findGroupPackageById = (packages: GroupPackage[], id?: string): GroupPackage | undefined => {
    return id !== undefined ? packages.find((currentPackage) => currentPackage._id === id) : undefined
}

export const getSpaceMinPriceByPerson = (searchResult: SpaceDetail): number => {
    let minPriceByPerson: number | null = null
    searchResult.groups.forEach((group) => {
        group.workingTimes.forEach((workingTime: WorkingTime) => {
            workingTime.ranges.forEach((range: GroupRange) => {
                if(minPriceByPerson === null) {
                    minPriceByPerson = range.prices.byPerson
                } else if(range.prices.byPerson < minPriceByPerson){
                    minPriceByPerson = range.prices.byPerson
                }
            })
        })
    })
    return minPriceByPerson ? minPriceByPerson : 0
}

export const checkHasServiceByPerson = (paidServices: PaidService[]): boolean => {
    for(let paidService of paidServices) {
        if(paidService.isByPerson) {
            return true
        }
    }
    return false
}

export const getWorkingTimeMinRange = (workingTimes: WorkingTime[], priceType: PriceTypes, hasServiceByPerson: boolean): GroupRange => {
    return hasServiceByPerson ? getMinOccupancyRange(workingTimes) : getMinPriceRange(workingTimes, priceType)
}

export const getMinPriceRange = (workingTimes: WorkingTime[], priceType: PriceTypes): GroupRange => {
    let minRange: GroupRange | null = null
    workingTimes.forEach(workingTime => {
        workingTime.ranges.forEach(range => {
            if(minRange === null) {
                minRange = range

            } else {
                const minRangePrice = getRangePrice(minRange, priceType)
                const rangePrice = getRangePrice(range, priceType)
                if(rangePrice < minRangePrice) {
                    minRange = range
                }
            }
        })
    })
    return minRange!
}

export const getWorkingTimeMaxRange = (workingTimes: WorkingTime[], priceType: PriceTypes, hasServiceByPerson: boolean): GroupRange => {
    return hasServiceByPerson ? getMaxOccupancyRange(workingTimes) : getMaxPriceRange(workingTimes, priceType)
}

export const getMaxPriceRange = (workingTimes: WorkingTime[], priceType: PriceTypes): GroupRange => {
    let maxRange: GroupRange | null = null
    workingTimes.forEach(workingTime => {
        workingTime.ranges.forEach(range => {
            if(maxRange === null) {
                maxRange = range
            } else {
                const maxRangePrice = getRangePrice(maxRange, priceType)
                const rangePrice = getRangePrice(range, priceType)
                if(rangePrice > maxRangePrice) {
                    maxRange = range
                }
            }
        })
    })
    return maxRange!
}

export const calculateBasePrice = (priceType: PriceTypes, price: number, people: number, eventHours: number): number => {
    if(priceType === PriceTypes.ByPerson) {
        return price * people
    } else if(priceType === PriceTypes.ByHour) {
        return price * eventHours
    } 
    return price
}

export const getMaxEventHours = (workingTimes: WorkingTime[]): number => {
    let maxEventHours: number | null = null
    workingTimes.forEach(workingTime => {
        if(maxEventHours === null) {
            maxEventHours = workingTime.maxHours
        } else if(workingTime.maxHours > maxEventHours) {
            maxEventHours = workingTime.maxHours
        }
    })
    return maxEventHours!
}

export const getTotalCost = (index:number, paymentsObject:IPayment[]):number => {
    return paymentsObject.slice(0, index + 1).reduce((total, item) => total + (item.amount + (item.lateFee ?? 0)), 0);
}