import { Box, Stack, styled, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { MIN_EVENT_HOURS, PaidService, PriceTypes, WorkingTime } from "interfaces/space"
import { calculateBasePrice, calculatePackagePrice, calculatePackagePricePerPerson, checkHasServiceByPerson, formatNumberPrice, getMaxEventHours, getMaxOccupancyRange, getMinOccupancyRange, getRangePrice, getWorkingTimeMaxRange, getWorkingTimeMinRange, roundAmount } from "utils"

interface GroupPackagesPricesByOccupancyProps {
    workingTimes: WorkingTime[]
    priceType: PriceTypes
    packagePaidServices: PaidService[]
    packagePrice: number
}

const Container = styled(Stack)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: '50%'
    }
}))

const Label = styled(Typography)(({theme}) => ({
    color: theme.palette.neutral50.light
}))

const GroupPackagesPricesByOccupancy: React.FC<GroupPackagesPricesByOccupancyProps> = (props) => {
    const { workingTimes, priceType, packagePaidServices, packagePrice } = props
    const hasServiceByPerson = priceType === PriceTypes.ByPerson ? true : checkHasServiceByPerson(packagePaidServices)
    const minRange = getWorkingTimeMinRange(workingTimes, priceType, hasServiceByPerson)
    const maxRange = getWorkingTimeMaxRange(workingTimes, priceType, hasServiceByPerson)
    const minPrice = getRangePrice(minRange, priceType)
    const maxPrice = getRangePrice(maxRange, priceType)
    const withDecimals = false
    
    const baseMinPrice = calculateBasePrice(priceType, minPrice, minRange.occupancy.minimum!, MIN_EVENT_HOURS)
    const minCost = calculatePackagePrice(
        minRange.occupancy.minimum!,
        packagePaidServices,
        baseMinPrice,
        packagePrice
    )
    const minCostByPerson = calculatePackagePricePerPerson(
        minCost, 
        hasServiceByPerson ? minRange.occupancy.minimum! : minRange.occupancy.maximum!
    )
    const baseMaxPrice = calculateBasePrice(priceType, maxPrice, maxRange.occupancy.maximum!, getMaxEventHours(workingTimes))
    const maxCost = calculatePackagePrice(
        maxRange.occupancy.maximum!,
        packagePaidServices,
        baseMaxPrice,
        packagePrice
    )
    const maxCostByPerson = calculatePackagePricePerPerson(
        maxCost, 
        hasServiceByPerson ? maxRange.occupancy.maximum! : maxRange.occupancy.minimum!
    )
    
    const formattedMinCost = formatNumberPrice(roundAmount(minCost), withDecimals)
    const formattedMinCostByPerson = formatNumberPrice(roundAmount(minCostByPerson), withDecimals)
    const formattedMaxCost = formatNumberPrice(roundAmount(maxCost), withDecimals)
    const formattedMaxCostByPerson = formatNumberPrice(roundAmount(maxCostByPerson), withDecimals)
    const isMinCostLessThanMaxCost = true;

    const t = useTranslate()
    const priceMinimumLabel = t("updateSpace.groupPackagesPriceMinimum")
    const priceMaximumLabel = t("updateSpace.groupPackagesPriceMaximum")
    const priceByPersonLabel = t("updateSpace.groupPackagesPriceByPerson")

    return <Stack spacing={2} sx={{mt: 3}}>
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            <Container>
                <Label variant="labelLarge">{priceMinimumLabel}</Label>
                <Label variant="labelLarge">{isMinCostLessThanMaxCost ? formattedMinCost : formattedMaxCost}</Label>
            </Container>
            <Container>
                <Label variant="labelLarge">{priceByPersonLabel}</Label>
                <Label variant="labelLarge">{isMinCostLessThanMaxCost ? formattedMinCostByPerson : formattedMaxCostByPerson}</Label>
            </Container>
        </Box>
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            <Container>
                <Label variant="labelLarge">{priceMaximumLabel}</Label>
                <Label variant="labelLarge">{isMinCostLessThanMaxCost ? formattedMaxCost : formattedMinCost}</Label>
            </Container>
            <Container>
                <Label variant="labelLarge">{priceByPersonLabel}</Label>
                <Label variant="labelLarge">{isMinCostLessThanMaxCost ? formattedMaxCostByPerson : formattedMinCostByPerson}</Label>
            </Container>
        </Box>
    </Stack>
}

export default GroupPackagesPricesByOccupancy