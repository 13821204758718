import { Button, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { useState } from "react"
import { GroupPackage, PaidService, PriceTypes, WorkingTime } from "interfaces/space"
import { CatalogEntry } from "interfaces/catalogEntry"
import { useTranslate } from "@pankod/refine-core"
import GroupPackagesPackageCard from "./GroupPackagesPackageCard"
import GroupPackagesCreateOrEditModal from "./GroupPackagesCreateOrEditModal"

interface GroupPackagesFormProps {
    workingTimes: WorkingTime[]
    priceType: PriceTypes
    groupAmenities?: CatalogEntry[]
    groupPaidServices?: PaidService[]
    packages?: GroupPackage[]
    originalAmenities?:CatalogEntry[]
    setPackages: (packages: GroupPackage[]) => void
}

const GroupPackagesForm: React.FC<GroupPackagesFormProps> = (props) => {
    const { packages, setPackages, groupAmenities, groupPaidServices, workingTimes, priceType, originalAmenities } = props
    const [openCreateOrEditModal, setOpenCreateOrEditModal] = useState<boolean>(false)
    const [selectedPackage, setSelectedPackage] = useState<GroupPackage | null>(null)
    const [selectedPackageIndex, setSelectedPackageIndex] = useState<number | null>(null)

    const t = useTranslate()
    const title = t("updateSpace.menuPackages")
    const subtitle = t("updateSpace.spacePackagesSubtitle")
    const addPackageLabel = t("updateSpace.spacePackagesAdd")
    const packagesLabel = t("updateSpace.spacePackagesTitle")

    const onAddPackageClickHandler = () => {
        setSelectedPackage(null)
        setSelectedPackageIndex(null)
        setOpenCreateOrEditModal(true)
    }

    const onEditPackageClickHandler = (packageIndex: number, packageData: GroupPackage) => {
        setSelectedPackage(packageData)
        setSelectedPackageIndex(packageIndex)
        setOpenCreateOrEditModal(true)
    }

    const onDeletePackageClickHandler = (packageIndex: number) => {
        deletePackage(packageIndex)
    }

    const onDuplicatePackageClickHandler = (packageIndex: number) => {
        duplicatePackage(packageIndex)
    }

    const onCloseCreateOrEditModal = () => {
        setOpenCreateOrEditModal(false)
    }

    const addPackage = (packageData: GroupPackage) => {
        const packagesCopy = packages ? [...packages] : []
        packagesCopy.push(packageData)
        setPackages(packagesCopy)
    }

    const updatePackage = (packageData: GroupPackage, packageIndex: number) => {
        const packagesCopy = [...packages!]
        packagesCopy[packageIndex] = packageData
        setPackages(packagesCopy)
    }

    const deletePackage = (packageIndex: number) => {
        const packagesCopy = [...packages!]
        packagesCopy.splice(packageIndex, 1)
        setPackages(packagesCopy)
    }

    const duplicatePackage = (packageIndex: number) => {
        const packagesCopy = [...packages!]
        const packageData = packagesCopy[packageIndex]
        packagesCopy.push(packageData)
        setPackages(packagesCopy)
    }
    
    return <Stack sx={{mt: 3}}>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, color: 'neutral50.light', letterSpacing: 0}}>{subtitle}</Typography>
        {
            packages && packages?.length > 0 && <Stack spacing={2} sx={{mt: 2}}>
                <Typography variant="labelLarge" color={'neutral50.light'}>{packagesLabel}</Typography>
                {
                    packages?.map((packageData: GroupPackage, index: number) => <GroupPackagesPackageCard
                        key={'package-card-' + index}
                        packageData={packageData}
                        workingTimes={workingTimes}
                        priceType={priceType}
                        packageIndex={index}
                        onEditPackage={onEditPackageClickHandler}
                        onDeletePackage={onDeletePackageClickHandler}
                        onDuplicatePackage={onDuplicatePackageClickHandler}
                    />)
                }
            </Stack>
        }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{width: 'max-content', mt: 2}}
            onClick={onAddPackageClickHandler}
        >
            <Typography variant='labelLarge'>{addPackageLabel}</Typography>
        </Button>
        {
            openCreateOrEditModal && <GroupPackagesCreateOrEditModal
                originalAmenities={originalAmenities}
                workingTimes={workingTimes}
                priceType={priceType}
                packageData={selectedPackage}
                packageIndex={selectedPackageIndex}
                onClose={onCloseCreateOrEditModal}
                open={openCreateOrEditModal}
                groupAmenities={groupAmenities}
                groupPaidServices={groupPaidServices}
                addPackage={addPackage}
                updatePackage={updatePackage}
            />
        }
    </Stack>
}

export default GroupPackagesForm