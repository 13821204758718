import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core";
import Divider from "components/Divider";
import { GroupPackage } from "interfaces/space";

interface SpaceReservationTypeProps {
    selectedPackage?: GroupPackage
}

const SpaceReservationType: React.FC<SpaceReservationTypeProps> = (props) => {
    const { selectedPackage } = props;
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const textVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    
    const t = useTranslate();
	const reservation_package = t('previewDetails.reservationPackage');
    const reservation_type = t('previewDetails.reservationType');
    
    return <Stack spacing={4}>
        <Divider/>
        {
            selectedPackage !== undefined 
            ?   <Stack>
                    <Typography variant={titleVariant}>{reservation_package}</Typography>
                    <Typography variant={textVariant}>{selectedPackage.name}</Typography>
                    <Typography variant={textVariant} style={{marginTop:'16px', whiteSpace:'break-spaces'}}>{selectedPackage.description}</Typography>
                </Stack> 
            :   <Typography variant={titleVariant}>{reservation_type}</Typography>
        }
    </Stack>
}

export default SpaceReservationType
