import { IBooking, IPaidService } from "interfaces";
import { Row, Label, Text, Subtitle } from "./components";
import { Box, fontWeight } from "@pankod/refine-mui";
import { useTranslate } from "@pankod/refine-core";
import { calculatePackagePrice, formatNumberPrice } from "utils";

interface RefundDetailProps {
    reservation: IBooking,
}


const RefundDetail: React.FC<RefundDetailProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    const refundPercent = t('refund.refundPercent');
    const refundWithPercent = refundPercent.replace("${percent}", (reservation.refoundPercent ? reservation.refoundPercent : 0).toString());


    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 0px'}}>
        <Row>
            <Text>
                <span style={{color:"#DD3730", fontWeight: "600"}}>
                    {t('refund.refundPayment')} 
                </span>
                <span style={{color:"#FF5449", fontWeight: "600"}}>
                    {refundWithPercent}
                </span>
            </Text>
            <Text sx={{display: "flex", alignItems:"center"}}>{formatNumberPrice(reservation?.refoundAmount ?? 0)}MXN</Text>
        </Row>
        <Row>
            <Text sx={{fontWeight:"600"}}>{t('refund.refundPolicy')}</Text>
            <Text>{reservation?.refoundPolicy!=''? reservation?.refoundPolicy : 'No aplica'}</Text>
        </Row>
        
    </Box>
}

export default RefundDetail;