import { Typography } from "@mui/material"
import ShowMoreButton from "components/ShowMoreButton"
import { useState } from "react"

interface SpacePackageCardDescriptionProps {
    description: string
    isSM: boolean
}

const SpacePackageCardDescription: React.FC<SpacePackageCardDescriptionProps> = (props) => {
    const { description, isSM } = props
    const limit = 100
    const [canShowAll, setCanShowAll] = useState(description.length <= limit)
    const textVariant = isSM ? 'titleSmall' : 'bodyLarge'

    const shortStyle = {
        height: isSM ? '60px' : '48px',
        overflow: 'hidden'
    }
    
    const largeStyle = {
        height: 'auto'
    }

    return <>
        <Typography variant={textVariant} sx={!canShowAll ? shortStyle : largeStyle} style={{whiteSpace:'break-spaces'}}>
            {description}
        </Typography>
        {
            !canShowAll && <ShowMoreButton setShowAll={setCanShowAll}/>
        }
    </>
}

export default SpacePackageCardDescription