import Summary from "components/details/summary";
import { IBooking } from "interfaces";
import Prices from "components/details/prices";
import LateFee from "components/details/lateFee";
import { useTranslate } from "@pankod/refine-core";
import Refund from "./refund";

interface ReservationDetailProps {
    reservation: IBooking,
}

const ReservationDetail: React.FC<ReservationDetailProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    let surcharges: {label: string, amount: number}[] = [];
    let totalSurcharges = 0;
    reservation.payments.forEach((payment) => {
        if(payment.lateFee) {
            const paymentNumberLabel = t('reservation.payment') + ' ' + payment.paymentNumber  + ' (' + payment.paymentNumber + ' ' + t('reservation.of') + ' ' + reservation.payments.length + ')';
            const lateFee = payment.lateFee! / 100;
            surcharges.push({
                label: paymentNumberLabel,
                amount: lateFee
            });
            totalSurcharges += lateFee;
        }
    });
        
    return <>
        <Summary
            reservation={reservation}
        ></Summary>
        <Prices
            reservation={reservation}
        ></Prices>
        {
            surcharges.length > 0 && <LateFee
                surcharges={surcharges}
            ></LateFee>
        }
        {
            (reservation.refoundAmount !==null && reservation.refoundAmount !==undefined) && <Refund
            reservation={reservation}
            ></Refund>
        }
        
    </>
}

export default ReservationDetail;