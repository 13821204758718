import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridToolbar,
  GridColumns,
  List,
  EditButton,
  DateField,
  BooleanField,
  debounce,
  FormControl,
  InputLabel,
  TextField
} from "@pankod/refine-mui";

import { IUserC } from "interfaces";
import { Check, Close } from "@mui/icons-material";

const getStatusTranslate = (status: string): string => {
  switch (status) {
    case 'IN REVIEW':
      return 'Por revisar'
    case 'APPROVED':
      return 'Aprobado'
    case 'REJECTED':
      return 'Rechazado'
    default:
      return '';
  }
}


const getRoleTranslate = (role: string): string => {
  switch (role) {
    case 'HOST':
      return 'Anfitrión'
    case 'GUEST':
      return 'Organizador'
    default:
      return '';
  }
}

export const UsersList: React.FC = () => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<IUserC>();

  const filterOptions = [
    {
      label: 'Es igual a',
      value: 'equals',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value === filterItem.value;
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
    {
      label: 'Contiene',
      value: 'contains',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value.includes(filterItem.value);
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
  ]

  const columns: GridColumns<IUserC> = [
    {
      field: "name",
      headerName: t("users.fields.name"),
      minWidth: 100,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "lastName",
      headerName: t("users.fields.lastname"),
      minWidth: 100,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "email",
      headerName: t("users.fields.email"),
      renderCell: function render({ row }) {
        return <p>{row.email}</p>;
      },
      minWidth: 100,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "phone",
      headerName: t("users.fields.phone"),
      renderCell: function render({ row }) {
        return <p>{row.phone}</p>;
      },
      maxWidth: 130,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "role",
      headerName: t("users.fields.role"),
      renderCell: function render({ row }) {
        return <p>{getRoleTranslate(row.role)}</p>;
      },
      valueGetter: (params) => {
        return getRoleTranslate(params.row.role);
      },
      maxWidth: 120,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "documentStatus",
      headerName: t("users.fields.status"),
      renderCell: function render({ row }) {
        if (row.role === 'GUEST') return "";
        if (!row.documentStatus) return "";
        return <p>{getStatusTranslate(row.documentStatus)}</p>;
      },
      valueGetter: (params) => {
        if (params.row.role === 'GUEST') return "";
        if (!params.row.documentStatus) return "";
        return getStatusTranslate(params.row.documentStatus);
      },
      maxWidth: 120,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "active",
      headerName: t("users.fields.isActive"),
      renderCell: function render({ row }) {
        return <BooleanField
          value={row.active === false ? false : true}
          trueIcon={<Check />}
          falseIcon={<Close />}
        />
      },
      valueGetter: (params) => {
        return params.row.active === false ? "No" : "Si";
      },
      maxWidth: 200,
      flex: 0.2,
      filterable: false,
      // filterOperators: filterOptions
    },
    {
      field: "updatedAt",
      headerName: t("users.fields.updatedAt"),
      renderCell: function render({ row }) {
        return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
      },
      maxWidth: 150,
      flex: 0.2,
      filterable: true,
      filterOperators: filterOptions
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("table.actions"),
      renderCell: function render({ row }) {
        if (row.role === 'GUEST') return "";
        return <EditButton hideText recordItemId={row.id} />;
      },
      align: "center",
      headerAlign: "center",
      minWidth: 80,
    }
  ];

  return (
    <List canCreate={false}>
      <DataGrid 
        {...dataGridProps} 
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        hideFooterPagination
        autoHeight />
    </List>
  );
};
