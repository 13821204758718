import { Box, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GroupPackage, MIN_EVENT_HOURS, PriceTypes, WorkingTime } from "interfaces/space"
import { calculateBasePrice, calculatePackagePrice, calculatePackagePricePerPerson, checkHasServiceByPerson, formatNumberPrice, getMaxEventHours, getMinOccupancyRange, getRangePrice, getWorkingTimeMaxRange, getWorkingTimeMinRange, roundAmount } from "utils"
import SpacePackageCardDescription from "./SpacePackageCardDescription"
import SpacePackageCardPaidServices from "./SpacePackageCardPaidServices"
import RoundedButton from "components/RoundedButton"

interface SpacePackageCardProps {
    packageInfo: GroupPackage
    workingTimes: WorkingTime[]
    priceType: PriceTypes
    onSelectPackage: (selectedPackage: GroupPackage) => void,
    isSM: boolean
}

const SpacePackageCard: React.FC<SpacePackageCardProps> = (props) => {
    const { packageInfo, workingTimes, priceType, onSelectPackage, isSM} = props
    const nameVariant = isSM ? 'titleMedium' : 'titleLarge'
    const priceVariant = isSM ? 'titleSmall' : 'titleMedium'
    const buttonVariant = isSM ? 'labelMedium' : 'titleMedium'
    
    const t = useTranslate();
	const viewDetails = t('previewDetails.viewDetails')
    const fromLabel = t('previewDetails.fromLabel');
    const perPerson = t('previewDetails.perPerson');

    const hasServiceByPerson = priceType === PriceTypes.ByPerson ? true : checkHasServiceByPerson(packageInfo.paidServices)
    const minRange = getWorkingTimeMinRange(workingTimes, priceType, hasServiceByPerson)
    const maxRange = getWorkingTimeMaxRange(workingTimes, priceType, hasServiceByPerson)
    const minPrice = getRangePrice(minRange, priceType)
    const maxPrice = getRangePrice(maxRange, priceType)
    const withDecimals = false
    
    const baseMinPrice = calculateBasePrice(priceType, minPrice, minRange.occupancy.minimum!, MIN_EVENT_HOURS)
    const minCost = calculatePackagePrice(
        minRange.occupancy.minimum!,
        packageInfo.paidServices,
        baseMinPrice,
        packageInfo.price
    )
    const minCostByPerson = calculatePackagePricePerPerson(
        minCost, 
        hasServiceByPerson ? minRange.occupancy.minimum! : minRange.occupancy.maximum!
    )
    const baseMaxPrice = calculateBasePrice(priceType, maxPrice, maxRange.occupancy.maximum!, getMaxEventHours(workingTimes))
    const maxCost = calculatePackagePrice(
        maxRange.occupancy.maximum!,
        packageInfo.paidServices,
        baseMaxPrice,
        packageInfo.price
    )
    const maxCostByPerson = calculatePackagePricePerPerson(
        maxCost, 
        hasServiceByPerson ? maxRange.occupancy.maximum! : maxRange.occupancy.minimum!
    )
    const priceByPerson = minCostByPerson < maxCostByPerson ? minCostByPerson : maxCostByPerson
    const formattedMinCostByPerson = formatNumberPrice(roundAmount(priceByPerson), withDecimals)
    
    const onReservePackage = () => {
        onSelectPackage(packageInfo)
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 6px 2px #00000026',
        borderRadius: '16px',
        padding: '16px',
        width: '100%',
        maxWidth: '519px',
        height: 'min-content'
    }}>
        <Stack spacing={'4px'} sx={{mb: '24px'}}>
            <Typography variant={nameVariant}>{packageInfo.name}</Typography>
            <SpacePackageCardDescription description={packageInfo.description} isSM={isSM}/>
        </Stack>
        <SpacePackageCardPaidServices paidServices={packageInfo.paidServices} isSM={isSM}/>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap', mt: '16px'}}>
            <Typography variant={priceVariant}>{fromLabel} {formattedMinCostByPerson} {perPerson}</Typography>
            <RoundedButton
                variant='contained'
                sx={{
                    height: {xs: '48px', md: '56px'},
                    padding: {xs: '16px', md: '16px 12px'},
                    minWidth: {xs: '151px', md: 'auto'}
                }}
                onClick={onReservePackage}
            >
                <Typography variant={buttonVariant}>{viewDetails}</Typography>
            </RoundedButton>
        </Box>
    </Box>
}

export default SpacePackageCard