import { Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GroupPackage, MIN_EVENT_HOURS, PriceTypes, WorkingTime } from "interfaces/space"
import { calculateBasePrice, calculatePackagePrice, calculatePackagePricePerPerson, checkHasServiceByPerson, formatNumberPrice, getMaxEventHours, getMaxOccupancyRange, getMinOccupancyRange, getRangePrice, getWorkingTimeMaxRange, getWorkingTimeMinRange, roundAmount } from "utils"

interface GroupDetailPackageProps {
    packageData: GroupPackage
    workingTimes: WorkingTime[]
    priceType: PriceTypes
}

const GroupDetailPackage: React.FC<GroupDetailPackageProps> = (props) => {
    const { packageData, workingTimes, priceType } = props
    const hasServiceByPerson = priceType === PriceTypes.ByPerson ? true : checkHasServiceByPerson(packageData.paidServices)
    const minRange = getWorkingTimeMinRange(workingTimes, priceType, hasServiceByPerson)
    const maxRange = getWorkingTimeMaxRange(workingTimes, priceType, hasServiceByPerson)
    const minPrice = getRangePrice(minRange, priceType)
    const maxPrice = getRangePrice(maxRange, priceType)
    const withDecimals = false
    
    const baseMinPrice = calculateBasePrice(priceType, minPrice, minRange.occupancy.minimum!, MIN_EVENT_HOURS)
    const minCost = calculatePackagePrice(
        minRange.occupancy.minimum!,
        packageData.paidServices,
        baseMinPrice,
        packageData.price
    )
    const minCostByPerson = calculatePackagePricePerPerson(
        minCost, 
        hasServiceByPerson ? minRange.occupancy.minimum! : minRange.occupancy.maximum!
    )
    const baseMaxPrice = calculateBasePrice(priceType, maxPrice, maxRange.occupancy.maximum!, getMaxEventHours(workingTimes))
    const maxCost = calculatePackagePrice(
        maxRange.occupancy.maximum!,
        packageData.paidServices,
        baseMaxPrice,
        packageData.price
    )
    const maxCostByPerson = calculatePackagePricePerPerson(
        maxCost, 
        hasServiceByPerson ? maxRange.occupancy.maximum! : maxRange.occupancy.minimum!
    )
    
    const formattedMinCost = formatNumberPrice(roundAmount(minCost), withDecimals)
    const formattedMinCostByPerson = formatNumberPrice(roundAmount(minCostByPerson), withDecimals)
    const formattedMaxCost = formatNumberPrice(roundAmount(maxCost), withDecimals)
    const formattedMaxCostByPerson = formatNumberPrice(roundAmount(maxCostByPerson), withDecimals)
    const formattedPackagePrice = formatNumberPrice(packageData.price)

    const t = useTranslate()
    const minPriceLabel = t("updateSpace.groupPackagesPriceMinimum")
    const maxPriceLabel = t("updateSpace.groupPackagesPriceMaximum")
    const priceByPersonLabel = t("updateSpace.groupPackagesPriceByPerson")
    const discountLabel = t("updateSpace.groupPackagesDiscount")

    return <Stack>
        <Typography variant="titleMedium">{packageData.name}</Typography>
        <Typography variant="bodyLarge" sx={{mt: 0.5}}>{packageData.description}</Typography>
        <Stack sx={{mt: 2}}>
            <Typography variant="bodyLarge">{minPriceLabel} {formattedMinCost}</Typography>
            <Typography variant="bodyLarge">{priceByPersonLabel} {formattedMinCostByPerson}</Typography>
            <Typography variant="bodyLarge">{maxPriceLabel} {formattedMaxCost}</Typography>
            <Typography variant="bodyLarge">{priceByPersonLabel} {formattedMaxCostByPerson}</Typography>
            <Typography variant="bodyLarge">{discountLabel} {formattedPackagePrice}</Typography>
        </Stack>
    </Stack>
}

export default GroupDetailPackage