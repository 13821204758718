import React from "react";
import Grid from "@mui/material/Grid";
import {Chip} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/material/styles";
import { PaidService } from "interfaces/space";
import PaidServicesLabel from "./PaidServicesLabel";

interface PaidServicesGroupProps {
    paidServices: PaidService[]
    onServiceClicked: (index: number, paidService: PaidService) => void
    onServiceRemoved: (paidService: PaidService) => void
}

const PaidServicesChipGroup: React.FC<PaidServicesGroupProps> = (props) => {
    const {onServiceClicked, paidServices, onServiceRemoved} = props
    const theme = useTheme()

    return <Grid>
        {paidServices.map((item, index) => {
            return <Chip
                key={`paid-service-chip-${item.name}-${index}`}
                sx={{borderRadius: '8px', mx: 1, mt: 1, minHeight: '48px', color: "secondaryContainer.light"}}
                onDelete={() => {
                    onServiceRemoved(item)
                }}
                deleteIcon={<CloseIcon sx={{"&&": {color: theme.palette.onSecondaryContainer.main}}}/>}
                label={<PaidServicesLabel paidService={item}/>}
                variant="filled"
                clickable
                onClick={() => {
                    onServiceClicked(index, item)
                }}
            />
        })}
    </Grid>
}

export default PaidServicesChipGroup