import { Box } from "@pankod/refine-mui";
import { IBooking } from "interfaces";
import { Subtitle } from "./components";
import { useTranslate } from "@pankod/refine-core";
import RefundDetail from "./refundDetail";

interface RefundProps {
    reservation: IBooking
}

const Refund: React.FC<RefundProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px'}}>
        <Subtitle>{t('refund.refundPayment')}</Subtitle>
        <RefundDetail
            reservation={reservation}
        ></RefundDetail>
    </Box>
}

export default Refund;