import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, FormControl, GridColumns, GridToolbar, InputLabel, List, TextField, Typography, debounce, styled, useDataGrid } from "@pankod/refine-mui";
import { IFinance, ICatalog, IBooking } from "interfaces";
import Details from "../bookings/details";
import ReservationStatus from "components/details/reservationStatus";
import { formatNumberPrice, getTotalCost } from "utils";
import Actions from "../../components/list/actions";
import PaymentStatus from "components/details/paymentStatus";
import { EPaymentStatus } from "enums";

const getStatusTranslate = (status: string): string => {
  switch (status) {
    case EPaymentStatus.PAID:
      return 'Pagado'
    case EPaymentStatus.TO_PAY:
      return 'Por pagar'
    case EPaymentStatus.PENDING:
      return 'Pago pendiente'
    case EPaymentStatus.EXPIRED:
      return 'Vencido'
    default:
      return '';
  }
}


const CellLabel = styled(Typography)(() => ({
  fontSize: '14.16px',
  lineHeight: '56.64px'
}));

export const FinancesList: React.FC = (props: any) => {
  const t = useTranslate();
  const { dataGridProps } = useDataGrid<ICatalog>();

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] = useState<IFinance | undefined>(undefined);

  const filterOptions = [
    {
      label: 'Es igual a',
      value: 'equals',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value === filterItem.value;
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
    {
      label: 'Contiene',
      value: 'contains',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value.includes(filterItem.value);
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
  ]

  const columns = React.useMemo<GridColumns<IFinance>>(
    () => [
      {
        field: "id",
        headerName: t("finances.fields.paymentId"),
        minWidth: 0,
        flex: 0.1,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "incrementId",
        headerName: t("finances.fields.bookingId"),
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "bookingId",
        headerName: t("finances.fields.confirmationNumber"),
        minWidth: 230,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "formattedStatus",
        headerName: t("finances.fields.eventStatus"),
        renderCell: function render({ row }) {
          return <ReservationStatus
              reservationStatus={row.status}
              sx={{
                fontSize: '14.16px'
              }}
          ></ReservationStatus>;
        },
        minWidth: 140,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "hostName",
        headerName: t("finances.fields.host"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.hostName}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guestName",
        headerName: t("finances.fields.guest"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.guestName}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "date",
        headerName: t("finances.fields.reservationDate"),
        renderCell: function render({ row }) {
          return <DateField value={row.date.substring(0,10)} format="DD/MM/YYYY"  />;
        },
        minWidth: 145,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "dateCreation",
        headerName: t("finances.fields.contratationDate"),
        renderCell: function render({ row }) {
          return row.dateCreation ? <DateField value={row.dateCreation.substring(0,10)} format="DD/MM/YYYY"  /> : '';
        },
        minWidth: 145,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },

      
      {
        field: "time",
        headerName: t("finances.fields.reservationTime"),
        minWidth: 145,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "advertisementName",
        headerName: t("finances.fields.spaceName"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.advertisementName}</CellLabel>;
        },
        minWidth: 200,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "subtotal",
        headerName: t("finances.fields.subtotal"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.subtotal, true):''}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "ivaAmount",
        headerName: t("finances.fields.ivaAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.ivaAmount, true):''}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guestCommissionAmount",
        headerName: t("finances.fields.guestComission"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.guestCommissionAmount, true):''}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guestCommissionIvaAmount",
        headerName: t("finances.fields.guestCommissionIva"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.guestCommissionIvaAmount, true):''}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guaranteeDeposit",
        headerName: t("finances.fields.guaranteeDeposit"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.guaranteeDeposit, true):''}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "grandTotal",
        headerName: t("finances.fields.totalAmountToPaid"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber===1?formatNumberPrice(row.grandTotal, true):''}</CellLabel>;
        },
        minWidth: 175,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "paymentNumber",
        headerName: t("finances.fields.paymentNumber"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber} {t("finances.of")} {row.totalPayments}</CellLabel>;
        },
        minWidth: 70,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "paymentStatus",
        headerName: t("finances.fields.paymentStatus"),
        renderCell: function render({ row }) {
          return <PaymentStatus
              paymentStatus={row.paymentStatus}
              sx={{
                fontSize: '14.16px'
              }}
          ></PaymentStatus>;
        },
        valueGetter: (params) => {
          return getStatusTranslate(params.row.paymentStatus);
        },
        minWidth: 175,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "paymentAmount",
        headerName: t("finances.fields.paymentAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.paymentAmount, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "lateFee",
        headerName: t("finances.fields.lateFee"),
        renderCell: function render({ row }) {
          const paymentData = row.payments[row.paymentNumber - 1]
          return <CellLabel>{formatNumberPrice((paymentData.lateFee ?? 0)/100, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "paidAmount",
        headerName: t("finances.fields.paidAmount"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          const paymentTotal = payStatus === EPaymentStatus.PAID ? getTotalCost((row.paymentNumber - 1),row.payments) : 0;
          return <CellLabel>{formatNumberPrice((paymentTotal/100), true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "hostCommissionAmount",
        headerName: t("finances.fields.hostCommission"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          const priceAmount = payStatus === EPaymentStatus.PAID ? row.totalPayments>1 ? row.hostCommissionAmount/100 : row.hostCommissionAmount : 0;
          return <CellLabel>{formatNumberPrice((priceAmount), true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "hostCommissionIvaAmount",
        headerName: t("finances.fields.hostCommissionIva"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          const priceAmount = payStatus === EPaymentStatus.PAID ? row.totalPayments>1 ? row.hostCommissionIvaAmount/100 : row.hostCommissionIvaAmount : 0;
          return <CellLabel>{formatNumberPrice((priceAmount), true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "hostAmount",
        headerName: t("finances.fields.hostAmount"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          return <CellLabel>{formatNumberPrice(payStatus === EPaymentStatus.PAID ? row.hostAmount : 0, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "stripeComissionAmount",
        headerName: t("finances.fields.stripeCommission"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          return <CellLabel>{formatNumberPrice(payStatus === EPaymentStatus.PAID ? (row.payments[row.paymentNumber - 1].stripeComissionAmount / 100) : 0, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "ifiestaComission",
        headerName: t("finances.fields.ifiestaComission"),
        renderCell: function render({ row }) {
          const payStatus = row.payments[row.paymentNumber - 1].pay_status;
          const payPercent = row.payments[row.paymentNumber - 1].percentPayment ? (row.payments[row.paymentNumber - 1].percentPayment / 100) : 0;
          let totalComission = 0
          let guestCommissionAmount = 0
          let guestCommissionIvaAmount = 0
          let hostCommission = 0
          let hostCommissionIva = 0
          let stripeCommission = 0

          if (row.totalPayments>1) {
            guestCommissionAmount = (row.guestCommissionAmount * payPercent);
            guestCommissionIvaAmount = (row.guestCommissionIvaAmount * payPercent);
            hostCommission = payStatus === EPaymentStatus.PAID ? (row.hostCommissionAmount/100) :  0;
            hostCommissionIva = payStatus === EPaymentStatus.PAID ? (row.hostCommissionIvaAmount/100) :  0;
            stripeCommission = payStatus === EPaymentStatus.PAID ? (row.payments[row.paymentNumber - 1].stripeComissionAmount / 100) : 0
            totalComission = guestCommissionAmount + guestCommissionIvaAmount + hostCommission + hostCommissionIva - stripeCommission
          }
          return <CellLabel>{formatNumberPrice( payStatus === EPaymentStatus.PAID ? row.totalPayments>1 ? totalComission : row.ifiestaComission  : 0, true)}</CellLabel>;
        },
        minWidth: 230,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
    ],
    [t]
  );

  return (
    <List>
      <Box sx={{position: 'relative'}}>
        <DataGrid 
          {...dataGridProps} 
          columns={columns} 
          components={{
            Toolbar: GridToolbar,
          }}
          autoHeight
        />
        <Actions
          reservations={dataGridProps.rows}
          setOpenSidebar={setOpenSidebar}
          setSelectedReservation={(reservation: IBooking) => {
            const selectedReservation = {...reservation as IFinance};
            selectedReservation.id = selectedReservation.bookingId;
            setSelectedReservation(selectedReservation);
          }}
        ></Actions>
      </Box>
      <Details
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        reservation={selectedReservation}
      ></Details>
    </List>
  );
};